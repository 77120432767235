import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IValidatable, ValidationResultModel} from '../../../shared/models/validation.models';
import {ILoadable} from '../../../shared/components/loading/loading.models';
import {TransmittalEditorModel} from '../../models/transmittal.models';
import {TransmittalService} from '../../services/transmittal.service';
import {TransmittalEditorService} from '../../services/transmittal-editor.service';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-transmittal-saved-form',
  templateUrl: './transmittal-saved-form.component.html',
  styleUrls: ['./transmittal-saved-form.component.scss']
})
export class TransmittalSavedFormComponent implements OnInit, IValidatable, ILoadable {
  @ViewChild('pdfPreviewForm', {static: false}) pdfPreviewForm: ElementRef;
  @Input('permissions') permissions: any;

  loading: boolean;
  hasLoaded: boolean;
  succeeded: boolean;
  validationResult: ValidationResultModel;
  identifier: string;
  pdfDownloadUrl: string;
  editorJson: string;
  editor: TransmittalEditorModel;

  constructor(
    private transmittalService: TransmittalService,
    private toastr: ToastrService,
    private transmittalEditorService: TransmittalEditorService,
  ) { }

  ngOnInit() {
    this.hasLoaded = true;
    this.setPdfUrl(this.editor.id);
    // Do something
  }

  async getPdf() {
    await this.transmittalService.getPdf(this.pdfDownloadUrl).subscribe(data => {
      if (!data) {
        this.toastr.error('There is no file to download', 'Error downloading file');
        return;
      }

      const file = new Blob([data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `${this.identifier}.pdf`;
      link.click();
    });
  }

  private setPdfUrl(id?: number) {
    if (!this.editor.issued)
      this.pdfDownloadUrl = this.transmittalService.getPreviewLink(this.editor.id);
    else
      this.pdfDownloadUrl = this.transmittalService.getDownloadLink(this.editor.id);
  }
}
