import {finalize} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import {DocumentService} from '../../services/document.service';
import {State} from '@progress/kendo-data-query';
import {PermissionService} from '../../../shared/services/permission.service';
import {GridDataService} from '../../../shared/services/grid-data.service';
import {TransmittalFiltersModel, TransmittalGridRowModel} from '../../../transmittals/models/transmittal.models';
import {GridColumn, GridColumnState} from '../../../shared/models/grid.models';
import {SystemPermissions} from '../../../shared/models/permissions.models';
import {DeletedEvent} from '../../../shared/models/events';
import {Router} from '@angular/router';
import * as Moment from 'moment';
import {ValidationResultModel} from '../../../shared/models/validation.models';
import {ApiOption} from '../../../shared/models/base.models';
import {TransmittalService} from '../../../transmittals/services/transmittal.service';
import {TransmittalEditorService} from '../../../transmittals/services/transmittal-editor.service';
import {KendoColumnResizeArgs} from '../../../shared/utils/kendo.utils';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-document-transmittal-grid',
  templateUrl: './document-transmittal-grid.component.html',
  styleUrls: ['./document-transmittal-grid.component.scss']
})
export class DocumentTransmittalGridComponent implements OnInit {
  @Input('documentId') documentId: number;
  @Output('countChange') countChange: EventEmitter<number> = new EventEmitter();
  @ViewChild('pdfPreviewForm', {static: false}) pdfPreviewForm: ElementRef;

  initialLoad: boolean = true;
  systemPermissions: SystemPermissions;
  hasLoaded: boolean;
  loading: boolean;
  state: State = {
    skip: 0,
    take: 20
  };
  pageSizes: number[] = [5, 10, 20, 50, 100];
  filters?: TransmittalFiltersModel = new TransmittalFiltersModel();
  gridData: GridDataResult;
  allSelected: boolean;
  editorJson: string;
  pdfDownloadUrl: string;
  validationResult: ValidationResultModel;
  columnState: GridColumnState = new GridColumnState([
    { name: 'Trans No', field: 'identifier', defaults: { width: 180 }  },
    { name: 'Proj No\'s', field: 'projectNumber', defaults: { width: 180 }  },
    { name: 'Issued on Date', field: 'date', defaults: { width: 170, sort: { dir: 'desc' } } },
    { name: 'No of Docs', field: 'documentCount', defaults: { width: 100 }  },
    { name: 'Sent to Sources', field: 'sourceNames', defaults: { width: 210 } },
    { name: 'Template', field: 'template', defaults: { width: 125 }, isDefault: false },
    { name: 'Issue Format', field: 'formatOfIssue', defaults: { width: 125 }, isDefault: false },
    { name: 'From Email Address', field: 'fromEmail', defaults: { width: 125 }, isDefault: false },
    { name: 'Filename', field: 'filename', defaults: { width: 125 }, isDefault: false },
    { name: 'File Path', field: 'filePath', defaults: { width: 125 }, isDefault: false },
    { name: 'Issued?', field: 'issued', defaults: { width: 100 } },
    { name: 'Ack.', field: 'acknowledgements', defaults: { width: 120 } },
    { name: 'Voided', field: 'voided', isDefault: false, defaults: { width: 125, filter: { operator: 'eq', value: false } } },
    { name: 'Voided Reason', field: 'reasonForIssue', isDefault: false, defaults: { width: 125 } },
    { name: 'Create Date', field: 'createDate', defaults: { width: 180 }  },
    { name: 'Created By', field: 'creatorName', defaults: { width: 180 }  }
  ]);
  acknowledgementStateOptions: ApiOption<string>[] = [];

  constructor(
    private router: Router,
    private documentService: DocumentService,
    private gridDataService: GridDataService,
    private transmittalService: TransmittalService,
    private permissionService: PermissionService,
    private toastr: ToastrService,
    private transmittalEditorService: TransmittalEditorService
  ) { }

  ngOnInit() {
    this.systemPermissions = this.permissionService.getPermissions();

    const canAccess = this.systemPermissions.transmittals.canAccess;

    this.gridDataService.initColumnState(this);

    this.gridDataService.applyCachedState('document-' + this.transmittalService.gridName, this);

    this.filters.displayDate = this.filters.date ? new Date(this.filters.date) : null;

    if (!this.filters.acknowledgementStateId)
      this.filters.acknowledgementStateId = '';

    if (canAccess) {
      this.transmittalService.getAcknowledgementStateFilterOptions().subscribe(options => this.acknowledgementStateOptions = options);
      this.updateGrid();
    }
    else {
      this.loading = false;
    }

    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  columnStateChanged($event: GridColumn[]) {
    this.gridDataService.saveColumnState('document-' + this.transmittalService.gridName, this);
  }

  resetGrid() {
    this.loading = true;
    this.gridDataService.resetGrid('document-' + this.transmittalService.gridName, this.transmittalService.baseUrl, this)
      .subscribe(() => {
        this.documentService.getSeajacksTransmittalLinkedGridData(this.state, this.documentId).subscribe(data => {
          this.gridData = data;
          this.loading = false;
        })
      });
  }

  resized(changes: KendoColumnResizeArgs[]) {
    let change = changes[0];
    let col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState('document-' + this.transmittalService.gridName, this, true);
  }

  resetColumnState() {
    this.gridDataService.resetColumnState('document-' + this.transmittalService.gridName, this);
  }

  changePageSizes(pageSize: number) {
    this.state.take = pageSize;
    this.updateGrid();
  }

  updateGrid() {
    this.documentId = this.documentId;
    this.documentService.getSeajacksTransmittalLinkedGridData(this.state, this.documentId, this.filters).pipe(
      finalize(() => {
        this.hasLoaded = true
      }))
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
        if (this.initialLoad == true) {
          this.countChange.next(data.total);
          this.initialLoad = false;
        }
      });
  }

  onVoided() {
    this.updateGrid();
  }

  onDeleted($event: DeletedEvent) {
    this.initialLoad = false;
    this.updateGrid();
  }

  acknowledgementStateFilterChanged(state: string) {
    this.updateGrid();
  }

  dateFilterChanged(value: Date) {
    this.loading = true;
    this.state.skip = 0;
    this.filters.date = this.filters.displayDate ? Moment(this.filters.displayDate).format() : null;
    this.updateGrid();
  }

  clearDateFilter() {
    this.filters.displayDate = null;
    this.dateFilterChanged(null);
  }

  edit(item: TransmittalGridRowModel) {
    let url = `/transmittals/${item.id}/edit`;
    this.router.navigateByUrl(url);
  }

  addDocuments(item: TransmittalGridRowModel) {
    this.router.navigateByUrl(`/transmittals/${item.id}/add-documents`);
  }

  viewDocuments(item: TransmittalGridRowModel) {
    this.router.navigateByUrl(`/transmittals/${item.id}/edit`);
  }

  viewDocumentsInGrid(dataItemId: number) {
    this.router.navigate([`/documents`], { queryParams: { transmittal: dataItemId } });
  }

  async downloadPDF(id: number, identifier: number) {
    this.setPdfUrl(true, id);

    await this.transmittalService.getPdf(this.pdfDownloadUrl).subscribe(data => {
      if (!data) {
        this.toastr.error('There is no file to download', 'Error downloading file');
        return;
      }

      const file = new Blob([data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `${identifier}.pdf`;
      link.click();
    });
  }

  async previewPDF(id: number, identifier: number) {
    this.setPdfUrl(false, id);
    await this.transmittalService.getPdf(this.pdfDownloadUrl).subscribe(data => {
      if (!data) {
        this.toastr.error('There is no file to download', 'Error downloading file');
        return;
      }

      const file = new Blob([data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `${identifier}.pdf`;
      link.click();
    });
  }

  private setPdfUrl(issued: boolean, id?: number) {
    if (!issued)
      this.pdfDownloadUrl = this.transmittalService.getPreviewLink(id);
    else
      this.pdfDownloadUrl = this.transmittalService.getDownloadLink(id);
  }
}
