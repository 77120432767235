import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {GridComponent, GridDataResult} from '@progress/kendo-angular-grid';
import {State} from '@progress/kendo-data-query';
import {DialogService} from '@progress/kendo-angular-dialog';
import {SelectEvent} from '@progress/kendo-angular-layout';
import {ILoadable} from '../../../shared/components/loading/loading.models';
import {ApiOption} from '../../../shared/models/base.models';
import {SystemPermissions} from '../../../shared/models/permissions.models';
import {PermissionService} from '../../../shared/services/permission.service';
import {TransmittalEditorService} from '../../services/transmittal-editor.service';
import {ValidationResultModel} from '../../../shared/models/validation.models';
import {PtKendoGridDirective} from '../../../kendo/directives/pt-kendo-grid/pt-kendo-grid.directive';
import {TransmittalService} from '../../services/transmittal.service';
import {DeletedEvent, TransmittalFiltersModel, TransmittalGridRowModel} from '../../models/transmittal.models';
import gridConfig from './transmittals-grid.config';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-transmittals-grid',
  templateUrl: './transmittals-grid.component.html',
  styleUrls: ['./transmittals-grid.component.scss']
})
export class TransmittalsGridComponent implements OnInit, OnDestroy, ILoadable {
  @ViewChild('pdfPreviewForm', {static: false}) pdfPreviewForm: ElementRef;
  @ViewChild('grid', {static: false}) grid: GridComponent;
  @ViewChild('ptGrid', {static: false}) ptGrid: PtKendoGridDirective;

  onDestroy: Subject<any> = new Subject();

  loading: boolean = false;
  hasLoaded: boolean = true;
  systemPermissions: SystemPermissions;
  filters?: TransmittalFiltersModel = new TransmittalFiltersModel();
  gridData: GridDataResult;
  editorJson: string;
  pdfDownloadUrl: string;
  validationResult: ValidationResultModel;
  acknowledgementStateOptions: ApiOption<string>[] = [];
  selectedTabIndex: number;
  gridBaseUrl: string;
  gridName: string;
  columns = gridConfig.columns;
  pageSizes: number[] = [5, 10, 20, 50, 100];
  state: State = {
    skip: 0,
    take: 10,
    sort: gridConfig.defaultSort,
    filter: gridConfig.defaultFilter
  };

  private readonly selectedTabKey: string = 'selected-transmittal-grid-tab';

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private transmittalService: TransmittalService,
    private permissionService: PermissionService,
    private toastr: ToastrService,
    private transmittalEditorService: TransmittalEditorService
  ) {
    const gridDataConfig = transmittalService.getGridDataConfig();
    this.gridBaseUrl = gridDataConfig.url;
    this.gridName = gridDataConfig.name;
  }

  ngOnInit() {
    this.loading = true;

    this.systemPermissions = this.permissionService.getPermissions();

    const canAccess = this.systemPermissions.transmittals.canAccess;

    this.selectedTabIndex = +localStorage.getItem(this.selectedTabKey) || (canAccess ? 0 : 1);

    this.filters.displayDate = this.filters.date ? new Date(this.filters.date) : null;

    if (!this.filters.acknowledgementStateId) {
      this.filters.acknowledgementStateId = '';
    }

    if (canAccess) {
      this.transmittalService
        .getAcknowledgementStateFilterOptions()
        .subscribe(options => this.acknowledgementStateOptions = options);
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  tabSelected($event: SelectEvent) {
    this.selectedTabIndex = $event.index;
    localStorage.setItem(this.selectedTabKey, this.selectedTabIndex.toString());
  }

  resetGrid() {
    this.loading = true;
    this.ptGrid
      .resetGrid({ state: true, otherFilters: true })
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      });
  }

  resetColumnState() {
    this.ptGrid
      .resetGrid({ columns: true })
      .subscribe();
  }

  updateGrid() {
    this.ptGrid
      .updateGrid()
      .subscribe();
  }

  edit(item: TransmittalGridRowModel) {
    this.router.navigateByUrl(`/transmittals/${item.id}/edit`);
  }

  onVoided() {
    this.updateGrid();
  }

  onDeleted($event: DeletedEvent) {
    this.updateGrid();
  }

  async downloadPDF(id: number, identifier: number) {
    this.setPdfUrl(true, id);
    await this.transmittalService.getPdf(this.pdfDownloadUrl).subscribe(data => {
      if (!data) {
        this.toastr.error('There is no file to download', 'Error downloading file');
        return;
      }

      const file = new Blob([data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `${identifier}.pdf`;
      link.click();
    });
  }
  viewDocumentsInGrid(dataItemId: number) {
    this.router.navigate([`/documents`], { queryParams: { transmittal: dataItemId } });
  }

  private setPdfUrl(issued: boolean, id?: number) {
    if (!issued) {
      this.pdfDownloadUrl = this.transmittalService.getPreviewLink(id);
    } else {
      this.pdfDownloadUrl = this.transmittalService.getDownloadLink(id);
    }
  }
}
